import { Identifier, useGetIdentity, UserIdentity } from "react-admin";

export default class User implements UserIdentity {
    id: Identifier;
    fullName: string;
    avatar: string;
    permissions: string[];

    public constructor(id: Identifier, fullName: string, avatar: string, permissions: string[]) {
        this.id = id;
        this.fullName = fullName;
        this.avatar = avatar;
        this.permissions = permissions;
    }

    canView(resource: string): boolean {
        if (resource === 'documents') {
            return this.permissions.includes('read:document');
        }
        if (resource === 'users') {
            return this.permissions.includes('read:user');
        }
        return this.permissions.includes('any:admin');
    }

    
    canCreate(resource: string): boolean {
        if (resource === 'documents') {
            return this.permissions.includes('create:document');
        }
        if (resource === 'users') {
            return this.permissions.includes('create:user');
        }
        return this.permissions.includes('any:admin');
    }

    canDestroy(resource: string): boolean {
        if (resource === 'documents') {
            return this.permissions.includes('destroy:document');
        }
        return this.permissions.includes('any:admin');
    }

    isAdmin(): boolean {
        return this.permissions.includes('any:admin');
    }
    
}

export const useUser = () => {
    const { identity, isLoading } = useGetIdentity();

    return { user: identity as User, loading: isLoading};
}